<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/collective.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/collective.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO PRACY</h4>
		</template>

		<template #title>
			<h2>Zbiorowe prawo pracy</h2>
		</template>

		<template #text>
			<p>Obszar zbiorowego prawa pracy, który obejmuje stosunki prawne pomiędzy Pracodawcą a ogółem pracowników
				oraz ich przedstawicieli, obejmuje między innymi usługi w zakresie:
			</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">opracowywania i weryfikacji projektów regulaminów, procedur, porozumień
					oraz innych wewnętrznych aktów z zakresu prawa pracy – ze wskazaniem poprawnych rozwiązań z zakresu
					prawa pracy;
				</li>
				<li class="offer__list-item">pomocy Pracodawcy w relacjach ze związkami zawodowymi oraz innymi
					przedstawicielstwami pracowników – np. europejska rada zakładowa, rada pracowników (pisma,
					negocjacje);
				</li>
				<li class="offer__list-item">pomocy w przygotowaniu, zaprojektowaniu, wdrożeniu oraz negocjacjach
					warunków świadczeń pracowniczych oraz umów z dostawcami takich usług (np. klinikami medycznymi,
					zakładami ubezpieczeń, podmiotami oferującymi świadczenia emerytalne);
				</li>
				<li class="offer__list-item">wsparcie Pracodawcy w procesie transferu pracowników</li>
				<li class="offer__list-item">prowadzenie szkoleń z zakresu prawa pracy dla kadry, pracowników i związków
					zawodowych;
				</li>
				<li class="offer__list-item">przygotowania wystąpień do Państwowej Inspekcji Pracy;</li>
				<li class="offer__list-item">restrukturyzacji przedsiębiorstwa;</li>
				<li class="offer__list-item">przejścia zakładu pracy w trybie art. 23(1) k.p.;</li>
				<li class="offer__list-item">rejestracji zakładowych układów zbiorowych pracy i protokołów
					dodatkowych;
				</li>
				<li class="offer__list-item">pełnego procesu zwolnień grupowych;</li>
				<li class="offer__list-item">pełnego procesu zmiany warunków zatrudnienia pracowników lub wybranej grupy
					pracowników.
				</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'collective labor law',
}
</script>
